import SeoHead from '../../SeoHead';

const LoginSeoHead = ({ flow }) => {
  if (flow === 'register-card') {
    return (
      <SeoHead
        title={'Register your card | Link Rewards'}
        description="Register your Link card online to check your Linkpoints balance, membership and discover exclusive promotions on the go."
      />
    );
  }

  return (
    <SeoHead
      title={'Log in | Link Rewards'}
      description="Log in to your Link account to manage your Linkpoints balance and discover more exclusive deals at your fingertips."
    />
  );
};

export default LoginSeoHead;
