import { useRouter } from 'next/router';

import {
  MobileLanding,
  MobileLandingApply,
  MobileLandingRegister,
} from '../components/login/views';
import LoginSeoHead from '../components/login/views/LoginSeoHead';

const Login = () => {
  const {
    query: { flow, noLayout },
  } = useRouter();
  let View = MobileLanding;

  if (flow === 'apply') {
    View = MobileLandingApply;
  }
  if (flow === 'register-card') {
    View = MobileLandingRegister;
  }

  return (
    <>
      <LoginSeoHead />
      {noLayout !== undefined && (
        <img
          css={`
            margin-top: 4rem;
          `}
          className="logo"
          src="/static/img/common/fpg50-logo.svg"
          alt="Link"
        />
      )}
      <View />
    </>
  );
};

export default Login;
